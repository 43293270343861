var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "form",
      {
        attrs: { id: "registerForm" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.validateBeforeSubmit.apply(null, arguments)
          },
        },
      },
      [
        _c(
          "div",
          { class: { "form-group": true } },
          [
            _vm._m(0),
            _vm._v(" "),
            _c("base-input", {
              attrs: {
                invalid: _vm.$v.registerData.name.$error,
                focus: "",
                type: "text",
                name: "name",
              },
              on: {
                input: function ($event) {
                  return _vm.$v.registerData.name.$touch()
                },
              },
              model: {
                value: _vm.registerData.name,
                callback: function ($$v) {
                  _vm.$set(_vm.registerData, "name", $$v)
                },
                expression: "registerData.name",
              },
            }),
            _vm._v(" "),
            _vm.$v.registerData.name.$error
              ? _c("div", [
                  !_vm.$v.registerData.name.required
                    ? _c("span", { staticClass: "text-danger" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$tc("validation.required")) +
                            "\n        "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.$v.registerData.name.minLength
                    ? _c("span", { staticClass: "text-danger" }, [
                        _vm._v("Name must be at least 3 letters"),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { class: { "form-group": true } },
          [
            _c("p", { staticClass: "input-label" }, [
              _vm._v(_vm._s(_vm.$t("register.email")) + " "),
              _c("span", { staticClass: "text-danger" }, [_vm._v(" * ")]),
            ]),
            _vm._v(" "),
            _c("base-input", {
              attrs: {
                invalid: _vm.$v.registerData.email.$error,
                focus: "",
                type: "email",
                name: "email",
              },
              on: {
                input: function ($event) {
                  return _vm.$v.registerData.email.$touch()
                },
              },
              model: {
                value: _vm.registerData.email,
                callback: function ($$v) {
                  _vm.$set(_vm.registerData, "email", $$v)
                },
                expression: "registerData.email",
              },
            }),
            _vm._v(" "),
            _vm.$v.registerData.email.$error
              ? _c("div", [
                  !_vm.$v.registerData.email.required
                    ? _c("span", { staticClass: "text-danger" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$tc("validation.required")) +
                            "\n        "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.$v.registerData.email.email
                    ? _c("span", { staticClass: "text-danger" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$tc("validation.email_incorrect")) +
                            "\n        "
                        ),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { class: { "form-group": true } },
          [
            _vm._m(1),
            _vm._v(" "),
            _c("base-input", {
              attrs: {
                invalid: _vm.$v.registerData.company_name.$error,
                focus: "",
                type: "text",
                name: "company_name",
              },
              on: {
                input: function ($event) {
                  return _vm.$v.registerData.company_name.$touch()
                },
              },
              model: {
                value: _vm.registerData.company_name,
                callback: function ($$v) {
                  _vm.$set(_vm.registerData, "company_name", $$v)
                },
                expression: "registerData.company_name",
              },
            }),
            _vm._v(" "),
            _vm.$v.registerData.company_name.$error
              ? _c("div", [
                  !_vm.$v.registerData.company_name.required
                    ? _c("span", { staticClass: "text-danger" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$tc("validation.required")) +
                            "\n        "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.$v.registerData.company_name.minLength
                    ? _c("span", { staticClass: "text-danger" }, [
                        _vm._v("Company name must be at least 3 letters"),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("p", { staticClass: "input-label" }, [
              _vm._v(_vm._s(_vm.$t("register.password")) + " "),
              _c("span", { staticClass: "text-danger" }, [_vm._v(" * ")]),
            ]),
            _vm._v(" "),
            _c("base-input", {
              attrs: {
                invalid: _vm.$v.registerData.password.$error,
                type: "password",
                name: "password",
                "show-password": "",
              },
              on: {
                input: function ($event) {
                  return _vm.$v.registerData.password.$touch()
                },
              },
              model: {
                value: _vm.registerData.password,
                callback: function ($$v) {
                  _vm.$set(_vm.registerData, "password", $$v)
                },
                expression: "registerData.password",
              },
            }),
            _vm._v(" "),
            _vm.$v.registerData.password.$error
              ? _c("div", [
                  !_vm.$v.registerData.password.required
                    ? _c("span", { staticClass: "text-danger" }, [
                        _vm._v(_vm._s(_vm.$tc("validation.required"))),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.$v.registerData.password.minLength
                    ? _c("span", { staticClass: "text-danger" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$tc(
                                "validation.password_min_length",
                                _vm.$v.registerData.password.$params.minLength
                                  .min,
                                {
                                  count:
                                    _vm.$v.registerData.password.$params
                                      .minLength.min,
                                }
                              )
                            ) +
                            " "
                        ),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c("base-button", { attrs: { type: "submit", color: "theme" } }, [
          _vm._v(_vm._s(_vm.$t("register.register"))),
        ]),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "other-actions row" }, [
      _c("div", { staticClass: "col-sm-12 text-sm-left mb-4" }, [
        _c(
          "p",
          [
            _vm._v("Already a user please \n    "),
            _c(
              "router-link",
              { staticClass: "login-link", attrs: { to: "login" } },
              [_vm._v("\n     Login\n    ")]
            ),
            _vm._v("\n    here\n    "),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "input-label" }, [
      _vm._v("Name "),
      _c("span", { staticClass: "text-danger" }, [_vm._v(" * ")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "input-label" }, [
      _vm._v("Company name "),
      _c("span", { staticClass: "text-danger" }, [_vm._v(" * ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }